import React from "react";
import cx from "classnames";
import { Image } from "src/components/image";
import { BlockCopy } from "src/components/block-copy";

import styles from "./about-page-section.module.css";

interface AboutPageSectionProps {
  section: {
    illustration?: any;
    title?: string;
    copy: [];
  };
}
export const AboutPageSection = ({ section }: AboutPageSectionProps) => {
  const { illustration, title, copy } = section;
  return (
    <section className={cx(styles.aboutPageSection)}>
      {illustration && (
        <div
          className={styles.imageContainer}
          style={
            {
              "--aspect-ratio":
                illustration &&
                illustration.asset &&
                illustration.asset.metadata.dimensions.aspectRatio,
            } as React.CSSProperties
          }
        >
          <Image
            imageId={illustration.asset._id}
            alt={illustration.alt ? illustration.alt : ""}
            width={
              illustration &&
              illustration.asset &&
              illustration.asset.metadata.dimensions.width
            }
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      )}
      <div className={styles.copyContainer}>
        {title && <h2 className={cx("")}>{title}</h2>}
        {copy && <BlockCopy copy={copy} />}
      </div>
    </section>
  );
};
