import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { AboutPageSection } from "src/components/about-page-section";

export interface AboutPageProps {
  pageContext: {
    main: {
      slug: {
        current: string;
      };
      title: string;
      sections: [
        {
          _key: string;
          illustration?: any;
          title?: string;
          copy: [];
        }
      ];
    };
    meta: {};
  };
  location: any;
  path: string;
}

const AboutPage = ({ location, pageContext, path }: AboutPageProps) => {
  const { main, meta } = pageContext;

  return (
    <RevealOnMount location={location}>
      <SEO metaInfo={meta} pagePath={path} slug={main.slug.current} />
      {main.sections.length > 0 &&
        main.sections.map((section) => (
          <AboutPageSection section={section} key={section._key} />
        ))}
    </RevealOnMount>
  );
};

export default AboutPage;
