import React from "react";
import cx from "classnames";
import BlockContent from "@sanity/block-content-to-react";
import { Serializer } from "src/utils/serializer";

interface BlockCopyProps {
  className?: string;
  copy: [];
}
export const BlockCopy = ({ copy, className }: BlockCopyProps) => {
  if (!copy) {
    console.error("Missing blocks");
    return null;
  }

  return (
    <div className={cx("block-content", className)}>
      <BlockContent blocks={copy} serializers={Serializer} />
    </div>
  );
};
