import React from "react";
import cx from "classnames";

import styles from "./table.module.css";

interface TableProps {
  data: any;
}
export const Table = ({ data }: TableProps) => {
  return (
    <table className={cx(styles.table)}>
      <tbody>
        {data.rows.map((row: [{ cells: [] }], rowIndex: number) => (
          <tr key={rowIndex} className={styles.row}>
            {row.cells.map((cell: string, cellIndex: number) => (
              <td key={`${rowIndex}-${cellIndex}`} className={styles.cell}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
