import React from "react";
import { Table } from "src/components/table";
import { InfoGraphics } from "src/components/info-graphics";
import { Link } from "src/components/link";
import { getInternalLinkPath } from "./getInternalLinkPath";

const ListSerializer = ({
  type,
  children,
}: {
  type: string;
  children: any;
}) => {
  return type === "bullet" ? <ul>{children}</ul> : <ol>{children}</ol>;
};

const ListItemSerializer = ({ children }: { children: any }) => {
  return <li>{children}</li>;
};

export const Serializer = {
  list: ListSerializer,
  listItem: ListItemSerializer,
  types: {
    table: (props: any) => {
      return <Table data={props.node} />;
    },
    productInfoGraphics: (props: any) => {
      return <p>product info graphics</p>;
    },
    infoGraphics: (props: any) => {
      return <InfoGraphics data={props.node} />;
    },
    spacer: () => {
      return <div className="spacer" />;
    },
  },
  marks: {
    tick: (props: { children: any }) => (
      <span className="tick">{props.children}</span>
    ),
    link: ({ mark, children }: { mark: any; children: any }) => {
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
    internalLink: ({ mark, children }: { mark: any; children: any }) => {
      const path = getInternalLinkPath(mark.reference.link);
      return (
        <Link type="internalLink" to={path}>
          {children}
        </Link>
      );
    },
  },
};
