import React from "react";
import cx from "classnames";
import { Image } from "src/components/image";

import styles from "./info-graphics.module.css";

interface InfoGraphicsProps {
  data: {
    infoGraphicsItems: [
      {
        _key: string;
        graphic: {
          asset: {
            _id: string;
          };
        };
        label: string;
      }
    ];
  };
}

export const InfoGraphics = ({ data }: InfoGraphicsProps) => {
  const { infoGraphicsItems } = data;
  return (
    <div className={styles.infoGraphics}>
      {infoGraphicsItems &&
        infoGraphicsItems.map((item) => {
          return (
            <div className={styles.item} key={item._key}>
              <div className={styles.graphic}>
                {item.graphic?.asset && (
                  <Image
                    className={styles.graphicImage}
                    imageId={item.graphic.asset._id}
                    width={240}
                    alt={item.label}
                  />
                )}
              </div>
              <span className={styles.label}>{item.label}</span>
            </div>
          );
        })}
    </div>
  );
};
